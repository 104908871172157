<template>
  <div class="system-view">
    <el-row :gutter="25">
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>车辆必备条件设置</span>
          </div>
          <el-row>
            <el-switch v-model="isCarAge" @change="changeSetting($event, 'isCarAge')"></el-switch> 车辆年龄小于等于
            <el-input size="mini" type="number" v-model="carAge" @blur="changeCarAgeSetting()"></el-input> 年
          </el-row>
          <el-row>
            <el-switch v-model="isCarQuarterTest" @change="changeSetting($event, 'isCarQuarterTest')"></el-switch> 车辆有季度检测有效记录
          </el-row>
          <el-row>
            <el-switch v-model="isCarTrafficInsurance" @change="changeSetting($event, 'isCarTrafficInsurance')"></el-switch> 车辆有交强险有效记录
          </el-row>
          <el-row>
            <el-switch v-model="isCarBusinessInsurance" @change="changeSetting($event, 'isCarBusinessInsurance')"></el-switch> 车辆有商业险有效记录
          </el-row>
          <el-row>
            <el-switch v-model="isCarDrivingLicense" @change="changeSetting($event, 'isCarDrivingLicense')"></el-switch> 车辆行驶证有效
          </el-row>
        </el-card>
      </el-col>
      <!-- <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>车辆必备条件设置</span>
          </div>
          <el-row>
            默认单价 <el-input size="mini" type="number" v-model="price1"></el-input> 元/方
          </el-row>
          <el-row>
            37米单价 <el-input size="mini" type="number" v-model="price2"></el-input> 元/方
          </el-row>
          <el-row>
            42米单价 <el-input size="mini" type="number" v-model="price3"></el-input> 元/方
          </el-row>
          <el-row>
            48米单价 <el-input size="mini" type="number" v-model="price4"></el-input> 元/方
          </el-row>
          <el-row>
            51米单价 <el-input size="mini" type="number" v-model="price5"></el-input> 元/方
          </el-row>
        </el-card>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'system-car',
  data () {
    return {
      isCarAge: true,
      isCarQuarterTest: true,
      isCarTrafficInsurance: true,
      isCarBusinessInsurance: true,
      isCarDrivingLicense: true,
      carAge: 8,
      price1: 16,
      price2: 16,
      price3: 18,
      price4: 24,
      price5: 28
    }
  },
  async created () {
    const data = await this.$http({
      url: this.$http.adornUrl('/rule/car/get'),
      method: 'post'
    })
    for (const key in data.datas) {
      if (data.datas.hasOwnProperty(key)) {
        const v = data.datas[key]
        this[key] = v
      }
    }
  },
  methods: {
    async changeSetting (value, prop) {
      console.log(value)
      console.log(prop)
      const data = await this.$http({
        url: this.$http.adornUrl('/rule/car/save'),
        method: 'post',
        data: {
          [prop]: value
        }
      })
      console.log(data)
    },
    async changeCarAgeSetting () {
      console.log('this.carAge = ' + this.carAge)
      const data = await this.$http({
        url: this.$http.adornUrl('/rule/car/save'),
        method: 'post',
        data: {
          'carAge': this.carAge
        }
      })
      console.log(data)
    }
  }
}
</script>
